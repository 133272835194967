import { Link } from "react-router-dom";
import mutual from "../../../../images/mutual_fund.jpg"
import "../../../Products/sidebar.css"
import "../../../Products/common.css"
import ScrollTop from "../../../body/Totop/ScrollTop"
import Whatsapp from "../../../body/Totop/Whatsappbutton";
const EquityS = () => {
    return (<>
    <div className="header-style" style={{background:"#fff"}}>
        EQUITY SCHEMES
        </div>
     <div className="align">
            <div className="inneralign">
            <img className="image" src={mutual}alt=""/>
            <Link to="/products/mutual_fund"><div className="subheading">Mutual Fund</div></Link>
                <Link to="/products/mutual_fund/equityS"><p className="sidebar">EQUITY SCHEMES</p></Link>
                <Link to="/products/mutual_fund/debt"><p className="sidebar">DEBT SCHEMES</p></Link>
                <Link to="/products/mutual_fund/hybrid"><p className="sidebar">HYBRID SCHEMES</p></Link>
                <Link to="/products/mutual_fund/solution"><p className="sidebar">SOLUTION ORIENTED SCHEMES</p></Link>
               
                
            </div>
            <div className="content">
                <div className="title">What Is Equity Schemes?</div>
                <p>
                   Equity schemes are mutual funds that primarily invest in stocks, offering potential capital growth. Managed by professionals, they provide investors with exposure to the stock market's growth potential. Consider your risk tolerance and financial goals before investing. 
                </p>
               </div>
        </div>
       
        <ScrollTop />
        <Whatsapp />
    </>)
}
export default EquityS;