import { Link } from "react-router-dom";
import gunny  from "../../../../images/gunny.jpg"
import "../../../Products/sidebar.css"
import "../../../Products/common.css"
import ScrollTop from "../../../body/Totop/ScrollTop"
import Whatsapp from "../../../body/Totop/Whatsappbutton";
const Alternate=()=>{
        return(
        <>
        <div className="header-style" style={{background:"#fff"}}>
            ALTERNATE FUNDS 
        </div>

        <div className="align">
            <div className="inneralign">
            <img className="image" src={gunny}alt=""/>
                <Link to="/products/others/pms"><div className="subheading">Other</div></Link>
                <Link to="/products/others/pms"><p className="sidebar">PORTFOLIO MANAGEMENT SERVICE(PMS)</p></Link>
                {/* <Link to="/products/others/alternate"><p className="sidebar">ALTERNATE FUNDS</p></Link> */}
                <Link to="/products/others/bonds"><p className="sidebar">BONDS & NCD</p></Link>
                <Link to="/products/others/loans"><p className="sidebar">LOANS</p></Link>    
            </div>
            <div className="content">
                <div className="title">What is Alternate Funds?</div>
                <p>
                    The stock market is a device for transferring money from the impatient to the patient." Warren Buffett"
                    
                    </p>

                <div className="title">How It Works?</div> 
               <p>
                A mutual fund is a collection of stocks, bonds, or other securities owned by a group of investors and managed by a professional investment company. For an individual investor, having a diversified portfolio is difficult. Mutual funds helps the individual investors to invest in equity and debt securities simultaneously. When investors invest a particular amount in mutual funds, he becomes the unit holder of corresponding units. In turn, mutual funds invest unit holders’ money in stocks, bonds or other securities that earn interest or dividend. This money is distributed to the unit holders. If the fund gets money by selling some stocks at higher price the unit holders are liable to get the capital gains.
                
                </p> 

              



            </div>
        </div>
        <ScrollTop/>
        <Whatsapp/>  
        </>
    )
}
export default Alternate;