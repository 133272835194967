import { Link } from "react-router-dom";
import insurance from "../../../../images/insurance.jpg"
import "../../../Products/sidebar.css"
import "../../../Products/common.css"
import ScrollTop from "../../../body/Totop/ScrollTop"
import Whatsapp from "../../../body/Totop/Whatsappbutton";
const Health=()=>{
    return(<>
     <div className="header-style" style={{background:"#fff"}}>
            HEALTH
        </div>
        <div className="align" >
            <div className="inneralign">
            <img className="image" src={insurance} alt=""/>
                <Link to="/products/insurance"><div className="subheading">Insurance</div></Link>
                <Link to="/products/insurance/health"><p className="sidebar">HEALTH</p></Link>
                <Link to="/products/insurance/life"><p className="sidebar">LIFE</p></Link>
                <Link to="/products/insurance/general"><p className="sidebar">GENERAL</p></Link>
                            
            </div>
            <div className="content">
                <div className="title">Health</div>
               <p>Prioritize your health and well-being with our comprehensive health insurance plans. We understand that medical expenses can be a significant burden, which is why our health insurance policies offer extensive coverage for hospitalization, medical treatments, and critical illnesses. </p>
               <p>With our wide network of hospitals and cashless claims facility, you can access quality healthcare without financial worries. Our range of health insurance plans caters to different budgets and needs, ensuring that you find the right coverage for you and your family. </p>
               <p>Trust Trend Financial Services to be your partner in maintaining your health and providing financial security in times of medical emergencies.</p>
            </div>
        </div>
        <ScrollTop />
        <Whatsapp />
    </>
    )
}
export default Health;