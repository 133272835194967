import React from 'react';
import "./card.scss"
import { Link } from 'react-router-dom';
const Card = () => {
  return (<>
  <div className='titlesub'>
  <h5 style={{fontWeight:"bold"}}>"Never Depend On A Single Income. Make An Investment To Create A Second Source"</h5>
  <h6 style={{fontWeight:"bold"}}>-Warren Buffet</h6>
<h2 className='product'>Our Offerings</h2>
<h4 className='service'>
  </h4>
  </div>
  <div style={{display:"flex",flexWrap:"wrap",gap:"20px"}}>
  <div className='container11'>
<h2 className='h2border'>Stock Broking</h2>
   <div class="overlay">
    
    <div class = "items"></div>
    <div class = "items head">
      <Link to="/products/market/equity" ><p className='hoveritem'>&#x2022;EQUITY </p></Link>
      <Link to="/products/market/ipo"><p className='hoveritem'>&#x2022;IPO </p></Link>
      <Link to="/products/market/derivatives"><p className='hoveritem'>&#x2022;DERIVATIVES </p></Link>
      <Link to="/products/market/commodities"><p className='hoveritem'>&#x2022;COMMODITIES </p></Link>
      <hr/>
    </div>
   
    
</div>
</div>
<div className='container1'>
<h2 className='h2border'>Mutual Fund</h2>
   <div class="overlay">
    
    <div class = "items"></div>
    <div class = "items head">
      <Link to="/products/mutual_fund/equityS" ><p className='hoveritem'>&#x2022;EQUITY SCHEMES</p></Link>
      <Link to="/products/mutual_fund/debt"><p className='hoveritem'>&#x2022;DEBT SCHEMES</p></Link>
      <Link to="/products/mutual_fund/hybrid"><p className='hoveritem'>&#x2022;HYBRID SCHEMES</p></Link>
      <Link to="/products/mutual_fund/solution"><p className='hoveritem'>&#x2022;SOLUTION ORIENTED SCHEMES</p></Link>
      {/* <Link to="/products/mutual_fund/otherS"><p className='hoveritem'>&#x2022;OTHER SCHEMES</p></Link> */}
      <hr/>
    </div>
   
    
    
</div>
</div>
<div className='container2'>
<h2 className='h2border'>Insurance</h2>
   <div class="overlay">
    <div class = "items"></div>
    <div class = "items head">
      <Link to="/products/insurance/health"><p className='hoveritem'>&#x2022;HEALTH</p></Link>
      <Link to="/products/insurance/life"><p className='hoveritem'>&#x2022;LIFE</p></Link>
      <Link to="/products/insurance/general"><p className='hoveritem'>&#x2022;GENERAL</p></Link>
      <hr/>
    </div>
   
    
    
</div>
</div>
<div className='container3'>
<h2 className='h2border'>Other Investments</h2>
   <div class="overlay">
    <div class = "items"></div>
    <div class = "items head">
      <Link to="/products/others/pms"><p className='hoveritem'>&#x2022;PORTFOLIO MANAGEMENT SERVICE</p></Link>
      {/* <Link to="/products/others/alternate"><p className='hoveritem'>&#x2022;Alternate Funds</p></Link> */}
      <Link to="/products/others/bonds"><p className='hoveritem'>&#x2022;BONDS & NCD</p></Link>
      <Link to="/products/others/loans"><p className='hoveritem'>&#x2022;LOANS</p></Link>
      <hr/>
    </div>
   
   
    
</div>
</div>
</div>

 </>)
};

export default Card;