import "./about.css"
import prof from "../../images/prof1.jpg"
import goal from "../../images/goal1.png"
import vision from "../../images/vision.png"
import ScrollTop from "../../component/body/Totop/ScrollTop"
import Whatsapp from "../../component/body/Totop/Whatsappbutton";
const About=()=>{
    return(
        <>
         <div className="header-style" style={{background:"#fff"}}>
          About US
        </div>
         <div class = "about-wrapper">
      <div class = "about-left">
        <div class = "about-left-content">
          <div>
            <div class = "shadow">
              <div class = "about-img">
                <img src = {prof} alt = "a"/>
              </div>
            </div>

            <h2>Mr. Bhushan Kadam</h2>
            <h3>Founder</h3>
          </div>

        </div>
        
      </div>

      <div class = "about-right">
        <h2>Our Vision</h2>
        <div class = "about-para">
          <p>Welcome to Trend Financial Services where we help you grow your wealth and reach your financial goals. Our goal is to empower individuals like you to reach new heights of financial success and with our team of financial experts, we will help you multiply your money while keeping your assets safe. At Trend Financial Services, we have meticulously curated a range of comprehensive services to meet your diverse financial needs. From trading and insurance to mutual funds investment, portfolio management services (PMS), bonds, and debentures, we will have you covered. We are not just another financial services firm, we are a dedicated team, here to guide and support you every step of the way. We believe in building long-lasting relationships based on trust, transparency, and personalized attention. Our vision for Trend Financial Services is driven by a strong belief that every individual, regardless of their financial background, should have access to reliable and innovative financial solutions. It is our unwavering commitment to excellence, integrity, and client centricity that sets us apart. With the guidance and expertise of our experienced professionals, Trend Financial Services strives to create a financial environment that empowers you to make informed decisions and secure a prosperous future. Your financial goals become our goals, and by providing you with the tools, knowledge, and strategies, together, we will turn those goals into a tangible reality. I invite you to join us on this exciting journey of financial growth and success. Together, we can unlock your full financial potential and pave the way for a brighter tomorrow. Let's get started!</p>
        
        </div>
       
      </div>
    </div>
    <div className="gridalign">
    <div class="gridContainer">
  <div class="grid-item"><h2><img src={goal} alt="" style={{height:"10%",width:"60%"}}/></h2></div>
  <div class="grid-item" style={{  backgroundColor: "#fc9e52"}}><p className="gridItemstyle">Goal</p>
    <p>Empower individuals to reach new heights of financial success with our experts</p></div>
  <div class="grid-item"style={{  backgroundColor: "#fc9e52"}}><p className="gridItemstyle">Vision</p>
    <p>Should have access to reliable and innovative financial solutions</p></div>
  <div class="grid-item"><h2><img src={vision} alt="" style={{height:"50%",width:"60%"}}/></h2></div>
  </div>
  <ScrollTop />
        <Whatsapp />
    </div>
    
   
        </>
    )
}
export default About;
