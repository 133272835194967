import { Link } from "react-router-dom";
import gunny  from "../../../../images/gunny.jpg"
import "../../../Products/sidebar.css"
import "../../../Products/common.css"
import ScrollTop from "../../../body/Totop/ScrollTop"
import Whatsapp from "../../../body/Totop/Whatsappbutton";
const Bonds=()=>{
        return(
        <>
        <div className="header-style" style={{background:"#fff"}}>
            BONDS 
        </div>

        <div className="align">
            <div className="inneralign">
            <img className="image" src={gunny}alt=""/>
                <Link to="/products/others/pms"><div className="subheading">Other</div></Link>
                <Link to="/products/others/pms"><p className="sidebar">PORTFOLIO MANAGEMENT SERVICE(PMS)</p></Link>
                {/* <Link to="/products/others/alternate"><p className="sidebar">ALTERNATE FUNDS</p></Link> */}
                <Link to="/products/others/bonds"><p className="sidebar">BONDS & NCD</p></Link>
                <Link to="/products/others/loans"><p className="sidebar">LOANS</p></Link>    
            </div>
            <div className="content">
            <div className="title">What is Bonds?</div>
            <p>

                Bonds are an important component of India's financial markets and provide a means for these entities to raise capital for various purposes, such as funding government operations, infrastructure projects, or business expansion.</p>
                <div className="title">  Here are some key types of Indian bonds:</div>
               <p>&#x2022;Corporate Bonds: Indian corporations issue bonds to raise capital for their business activities, including expansion, debt refinancing, or working capital. These bonds vary in terms of credit quality and risk, and they may be rated by credit rating agencies to help investors assess their creditworthiness.</p>
                <p>&#x2022;Tax-Free Bonds: In the past, the Indian government has issued tax-free bonds, which are exempt from income tax. These bonds are typically used to fund infrastructure projects and offer tax benefits to investors.</p>
                <div className="title">What is NCD?</div>
               <p>NCD in India stands for "Non-Convertible Debenture." Non-Convertible Debentures are debt instruments issued by corporations and other entities to raise funds. These instruments are referred to as "non-convertible" because they cannot be converted into equity shares of the issuing company. NCDs are a common way for companies to raise capital in the Indian financial market. </p>
               <div className="title">Here are some key features and aspects of NCDs in India:</div> 
<p>
    
&#x2022; Debt Instrument: NCDs are a form of debt securities, and when an investor buys NCDs, they are essentially lending money to the issuer in exchange for periodic interest payments and the return of the principal amount at maturity.
    </p>
<p>

&#x2022;Interest Rate: NCDs offer a fixed or floating interest rate, which is referred to as the "coupon rate." The coupon rate is typically higher than what is offered by banks on fixed deposits, making NCDs an attractive investment option for income-oriented investors.

</p>
            </div>
        </div>
        <ScrollTop/>
        <Whatsapp/>  
        </>
    )
}
export default Bonds;