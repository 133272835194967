import React, { useEffect, useState } from "react";
import "./navbar.css";
import trend from "../../images/logo_white.jpg"
import { Link, Outlet } from "react-router-dom";
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 80) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <><div className={`main-header ${scrolled ? "sticky-header" : ""}`}>


      <header>
 
              <img style={{ height: "12%", width: "12%" ,paddingLeft:"15px"}} src={trend} alt="NO img" />
       

        <input type="checkbox" id="menu-bar"></input>
        <label for="menu-bar">Menu</label>

        <nav className="navbar1">
          <ul>
           
            <li><Link to="/">Home</Link></li>
            <li><Link to="#"><div style={{display:'flex',justifyContent:'center',textAlign:'center'}}> About Us <KeyboardArrowDownIcon /></div></Link>
              <ul>
                <li>
                  <Link to="/aboutus/about">Who we are</Link>
                </li>
                <li>
                  <Link to="/aboutus/testimonials">Testimonials</Link>  
                </li>
              </ul>
            </li>
            <li><a href="#" ><div style={{display:'flex',justifyContent:'center',textAlign:'center'}}> Products <KeyboardArrowDownIcon /></div></a>
              <ul>
                <li><Link to="/products/market">Stock Broking</Link></li>
                <li><Link to="/products/mutual_fund">Mutual Fund</Link></li>
                <li><Link to="/products/insurance">Insurance</Link></li>
                <li><Link to="/products/others/pms">Other</Link></li>
              </ul>
            </li>
            <li><Link to="/tools/SIP"><div style={{display:'flex',justifyContent:'center',textAlign:'center'}}> Tools <KeyboardArrowDownIcon /></div></Link>
              <ul>
                <li><Link to="/tools/SIP">SIP Calculator</Link></li>
                <li><Link to="/tools/LUMPSUM">LUMPSUM Calculator</Link></li>
              </ul>
            </li>
            {/* <li><Link to="/contactus">Contact Us</Link></li> */}

            <li><a href="https://instagram.com/trend_financialservices?igshid=NDk5N2NlZjQ="><InstagramIcon /></a></li>
            <li><a href="https://www.facebook.com/profile.php?id=100091002425526&mibextid=ZbWKwL"><FacebookIcon /></a></li>
            <li><a href="https://investorlogin.trendfinserv.com"><button >Login</button></a></li>
          </ul>
          <ul></ul>
        </nav>

       
        <div style={{ display: "flex" }}>
          <div className="insta"></div>
          <div className="facebook"></div>


        </div>
      </header>

    </div>

      <Outlet />

    </>
  );
};
export default Navbar;
