import { Link } from "react-router-dom";
import mutual from "../../../../images/mutual_fund.jpg"
import "../../../Products/sidebar.css"
import "../../../Products/common.css"
import ScrollTop from "../../../body/Totop/ScrollTop"
import Whatsapp from "../../../body/Totop/Whatsappbutton";
const Solution = () => {
    return (<>
    <div className="header-style" style={{background:"#fff"}}>
        SOLUTION ORIENTED SCHEMES
        </div>
     <div className="align">
            <div className="inneralign">
            <img className="image" src={mutual}alt=""/>
                <Link to="/products/mutual_fund"><div className="subheading">Mutual Fund</div></Link>
                <Link to="/products/mutual_fund/equityS"><p className="sidebar">EQUITY SCHEMES</p></Link>
                <Link to="/products/mutual_fund/debt"><p className="sidebar">DEBT SCHEMES</p></Link>
                <Link to="/products/mutual_fund/hybrid"><p className="sidebar">HYBRID SCHEMES</p></Link>
                <Link to="/products/mutual_fund/solution"><p className="sidebar">SOLUTION ORIENTED SCHEMES</p></Link>
                
            </div>
            <div className="content">
                <div className="title">What Is Solution Oriented Schemes?</div>
               <p>Solution-oriented schemes are a category of mutual funds in India designed to meet specific financial goals like retirement or education. These schemes have a lock-in period, typically of five years, and are meant to offer solutions tailored to investors' needs.</p><p>They provide a disciplined approach to achieve long-term objectives and offer potential tax benefits under Section 80C of the Income Tax Act.</p>
            </div>
        </div>
        <ScrollTop />
        <Whatsapp />
        
    </>)
}
export default Solution;