import React from 'react';
import "./test.css"
import ScrollTop from "../../component/body/Totop/ScrollTop"
import Whatsapp from "../../component/body/Totop/Whatsappbutton";
import profile from "../../images/profile.jpg"
const Testimonials = () => {

  return (
    <>
      <div className='header'>
        TESTIMONIALS
      </div>
      <div className="content1">
        <figure class="snip1192">
          <blockquote>I would like to play some record the excellent role played by my financial
            advisor and broker Mr. Bhushan in            maintaining my portfolio over the last 4
            year's. He's been very successful in            predicting the up's and downs of the
            share market in trying times, thus            significantly enhancing my wealth. He
            brings with him a wealth of information            and knowledge. His advice is always been
            exceptional and looking forward to            retaining his services for many many            more years to come.</blockquote>
          <div class="author">
            <img src={profile} alt="sq-sample1" />
            <h5><span> Padmashri</span>Dr. Keiki R. Mehta </h5>
          </div>
        </figure>
        <figure class="snip1192">
          <blockquote>The service is very nice & all are so cooperative.. Thank you so much for the excellent service.. Thank you bhusan & trend financial.. 🙏🙏</blockquote>
          <div class="author">
            <img src={profile} alt="sq-sample1" />
            <h5>Mrs. Vaibhavi Joshi </h5>
          </div>
        </figure>
        <figure class="snip1192 hover">
          <blockquote>Mr. Bhushan Kadam of Trend Financial Services is a fast-acting, knowledgeable advisor who quickly provides clients with sound advice, guidance, and support. His extensive expertise reliably produces excellent results. Wishing Trends Financial services all the very best.</blockquote>
          <div class="author">
            <img src={profile} alt="sq-sample1" />
            <h5>Mrs. Nikita Gomes</h5>
          </div>
        </figure>

        <figure class="snip1192">
          <blockquote>I am Mr.Bhagwan Bhoir from Navi mumbai, I am writing this appreciation note for Mr. Bhushan Kadam. He is handling my equity and mutual funds portfolios.his thorough knowledge about equity and MFs is very impressive and helpful to me. I wish him and his team alot of success and thank you once again for ur guidance and services</blockquote>
          <div class="author">
            <img src={profile} alt="sq-sample1" />
            <h5>Mr. Bhagwan Bhoir</h5>
          </div>
        </figure>
        <figure class="snip1192">
          <blockquote>I have opened an account in this firm for almost 1 year and my experience is very good, they are very helpful, they clear all doubts in details and their service is very good.</blockquote>
          <div class="author">
            <img src={profile} alt="sq-sample1" />
            <h5>Ms. Prachi Kale</h5>
          </div>
        </figure>
        <figure class="snip1192">
          <blockquote>Great service by Mr. Kadam. Due to my
work schedule, I have been unable to
constantly keep a track on my stocks but
he has professionally handled my
portfolio very well. Motivation blended
with Determination, he is good at
ensuring good returns to the client. Keep
it up.</blockquote>
          <div class="author">
            <img src={profile} alt="sq-sample1" />
            <h5>Ms. Sneha Kotian</h5>
          </div>
        </figure>
        <figure class="snip1192">
          <blockquote>
            I am one of the client of Nuvama wealth management. My relationship manager Mr Bhushan Kadam guided me very cooperatively in the wealth creation. The quick response to the call & e-mail from Mr Bhushan  helped me to grow in the stock market. The suggestion about the market help us to take a risk on a very strong basis. Thanks Mr Bhushan for your support & knowledge shared with us from time to time
          </blockquote>
          <div class="author">
            <img src={profile} alt="sq-sample1" />
            <h5>Mrs. Vibha Dalvi</h5>
          </div>
        </figure>
        <figure class="snip1192">
          <blockquote>
         
          I m Dr Ansari link with ur company and office close to one year now 
          And enjoyed ur services and expertise for all stock related problems or any other difficulties u have been at good services for everything. And hope u will continue same forever  . Thanks for for all support.
          </blockquote>
          <div class="author">
            <img src={profile} alt="sq-sample1" />
            <h5>Dr. Ansari </h5>
          </div>
        </figure>
        <figure class="snip1192">
          <blockquote>
            I have been investing through Trend Financial Services for SIP for the past three years, and it has been a remarkably rewarding journey. The SIP has provided me with a structured and disciplined approach to investing, which has helped me achieve my financial goals.
            The ease of setting up automatic deductions from my bank account and the flexibility to choose my investment amount has made the process extremely convenient. Plus, the company's regular updates and statements provide me with transparency and peace of mind.
            Over these three years, I've seen significant growth in my portfolio, and the returns have exceeded my expectations. The company's range of well-managed mutual funds allowed me to diversify my investments effectively.
            Overall, my experience with their SIP has been positive, and I plan to continue investing with them in the future.
          </blockquote>
          <div class="author">
            <img src={profile} alt="sq-sample1" />
            <h5>Mr. Sagar Gholap</h5>
          </div>
        </figure>

        <ScrollTop />
        <Whatsapp />
      </div>
    </>
  )
}
export default Testimonials;