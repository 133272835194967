import { Link } from "react-router-dom";
import insurance from "../../../../images/insurance.jpg"
import "../../../Products/sidebar.css"
import "../../../Products/common.css"
import ScrollTop from "../../../body/Totop/ScrollTop"
import Whatsapp from "../../../body/Totop/Whatsappbutton";
const General=()=>{
    return(<>
     <div className="header-style" style={{background:"#fff"}}>
            GENERAL
        </div>
        <div className="align">
            <div className="inneralign">
            <img className="image" src={insurance} alt=""/>
                <Link to="/products/insurance"><div className="subheading">Insurance</div></Link>
                <Link to="/products/insurance/health"><p className="sidebar">HEALTH</p></Link>
                <Link to="/products/insurance/life"><p className="sidebar">LIFE</p></Link>
                <Link to="/products/insurance/general"><p className="sidebar">GENERAL</p></Link>
                            
            </div>
            <div className="content">
                <div className="title">General</div>
               <p>Safeguard your valuable assets against unforeseen risks with our comprehensive general insurance solutions. Our firm offers a range of policies to protect your property, vehicles, and other assets from accidents, theft, natural disasters, and more. We provide prompt claims settlement and personalized service to ensure that your claims experience is smooth and hassle-free.</p>
               <p>Whether it's your home, office, or vehicle, our general insurance coverage gives you the confidence to face unexpected events. Join hands with us for reliable and efficient general insurance that protects what matters most to you.</p>
            </div>
        </div>
        <ScrollTop />
        <Whatsapp />
    </>
    )
}
export default General;