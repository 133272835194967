import { Link } from "react-router-dom";
import SB from "../../../../images/SB.jpg"
import "../../../Products/common.css"
import "../../../Products/sidebar.css"
import ScrollTop from "../../../body/Totop/ScrollTop"
import Whatsapp from "../../../body/Totop/Whatsappbutton";
const Commodities=()=>{
    
    return(
        <>
        <div className="header-style"style={{background:"#fff"}}>
        COMMODITIES
        </div>

        <div className="align">
            <div className="inneralign">
            <img className="image" src={SB}alt=""/>
                <Link to="/products/market"><div className="subheading">Stock Broking</div></Link>
                <Link to="/products/market/equity"><p className="sidebar">EQUITY</p></Link>
                <Link to="/products/market/ipo"><p className="sidebar">IPO</p></Link>
                <Link to="/products/market/derivatives"><p className="sidebar">DERIVATIVES</p></Link>
                <Link to="/products/market/commodities"><p className="sidebar">COMMODITIES</p>   </Link>                  
            </div>
            <div className="content">
                <div className="title">Commodities</div>
                <p>
                    Unleash the potential of global markets with our firm's premier commodities trading services. From precious metals to energy resources and agricultural products, our comprehensive range of commodities enables you to diversify your portfolio and capitalize on market trends. 
                    
                    </p>
                    <p>
                    With our advanced trading platform and expert guidance, we empower you to navigate the complexities of commodities trading with confidence. Opt for our financial services firm to unlock new avenues of wealth creation, seize opportunities, and stay ahead in the dynamic world of commodities trading.
                        
                    </p>
            </div>
        </div>
        <ScrollTop/>
        <Whatsapp/>  
        </>
    )
}
export default Commodities;