import { Link } from "react-router-dom";
import SB from "../../../images/SB.jpg"
import "../../Products/sidebar.css"
import "./markets.css"
import ScrollTop from "../../body/Totop/ScrollTop"
import Whatsapp from "../../body/Totop/Whatsappbutton";
const Market=()=>{
    
    return(
        <>
        <div className="header-style" style={{background:"#fff"}}>
        Stock Broking
        </div>

        <div className="align">
            <div className="inneralign">
            <img className="image" src={SB}alt=""/>
                <Link to="/products/market"><div className="subheading" >Stock Broking</div></Link>
                <Link to="/products/market/equity"><p className="sidebar">EQUITY</p></Link>
                <Link to="/products/market/ipo"><p className="sidebar">IPO</p></Link>
                <Link to="/products/market/derivatives"><p className="sidebar">DERIVATIVES</p></Link>
                <Link to="/products/market/commodities"><p className="sidebar">COMMODITIES</p>   </Link>             
            </div>
            <div className="content">
                <div className="title">What Is Stock Market?</div>
                <div className="subcontent">  The stock market is a financial marketplace where individuals and institutions buy and sell ownership shares of publicly traded companies. It provides a platform for companies to raise capital by issuing shares to the public, and for investors to buy and sell those shares. </div>
               <div className="title">Here are some key aspects of the stock market:</div>
               <div className="subcontent">


<p>&#x2022;Publicly Traded Companies: Many companies choose to "go public" by offering shares of their company for sale to the general public. This is typically done through an initial public offering (IPO). Once a company's shares are publicly traded, they can be bought and sold on the stock market.
</p>

<p>
&#x2022;Stocks and Shares: The terms "stock" and "shares" are often used interchangeably. When you buy a stock or share in a company, you are purchasing a piece of ownership in that company. The more shares you own, the larger your ownership stake in the company.

</p>
<p>
&#x2022;Investors: Individuals, institutions, and even governments can be investors in the stock market. They buy shares for various reasons, including the potential for capital appreciation (increased stock value) and dividend income.

</p>
<p>

&#x2022;Risk and Rewards: Investing in the stock market comes with inherent risks. Stock prices can be volatile, and there is no guarantee of profit. However, historically, the stock market has provided competitive returns over the long term.
</p>



<p>

The stock market plays a crucial role in the global economy, as it allows companies to raise funds for growth and expansion while providing individuals and institutions with investment opportunities to build and manage their wealth. It's important to note that investing in the stock market carries risks, and it's advisable for individuals to do their research or consult with financial professionals before making investment decisions.
</p>
               </div>
            </div>
        </div>
        <ScrollTop/>
        <Whatsapp/>  
        </>
    )
}
export default Market;