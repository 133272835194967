import { Link } from "react-router-dom";
import gunny  from "../../../images/gunny.jpg"
import "../../Products/sidebar.css"
import "../../Products/common.css"
import ScrollTop from "../../body/Totop/ScrollTop"
import Whatsapp from "../../body/Totop/Whatsappbutton";
const Others=()=>{
    return(<>
    <div className="header-style" style={{background:"#fff"}}>
            Other
        </div>
        <div className="align">
            <div className="inneralign">
                <img className="image" src={gunny}alt=""/>
                <Link to="/products/others/pms"><div className="subheading">Other</div></Link>
                <Link to="/products/others/pms"><p className="sidebar">PORTFOLIO MANAGEMENT SERVICE(PMS)</p></Link>
                <Link to="/products/others/bonds"><p className="sidebar">ALTERNATE FUNDS</p></Link>
                <Link to="/products/others/bonds"><p className="sidebar">BONDS & NCD</p></Link>
                <Link to="/products/others/loans"><p className="sidebar">LOANS</p></Link>
                            
            </div>
            <div className="content">
                <div className="title">What Is Stock Market?</div>                
            </div>
        </div>
        <ScrollTop/>
        <Whatsapp/>  
    </>
    )
}
export default Others;