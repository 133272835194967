import { Link } from "react-router-dom";
import SB from "../../../../images/SB.jpg"
import "../../../Products/sidebar.css"
import "../../../Products/common.css"
import ScrollTop from "../../../body/Totop/ScrollTop"
import Whatsapp from "../../../body/Totop/Whatsappbutton";
const Derivatives=()=>{
    
    return(
        <>
        <div className="header-style" style={{background:"#fff"}}>
        DERIVATIVES
        </div>

        <div className="align">
            <div className="inneralign">
            <img className="image" src={SB}alt=""/>
                <Link to="/products/market"><div className="subheading">Stock Broking</div></Link>
                <Link to="/products/market/equity"><p className="sidebar">EQUITY</p></Link>
                <Link to="/products/market/ipo"><p className="sidebar">IPO</p></Link>
                <Link to="/products/market/derivatives"><p className="sidebar">DERIVATIVES</p></Link>
                <Link to="/products/market/commodities"><p className="sidebar">COMMODITIES</p>   </Link>               
            </div>
            <div className="content">
                <div className="title">Derivatives</div>
               <p>
                Empower your financial journey with derivative trading, offered exclusively by our trusted financial services firm. Whether you're seeking to protect your investments or seize lucrative opportunities, our comprehensive range of derivative products provides the tools you need. 

                </p> 
               <p>
                Backed by our expertise and unwavering commitment to your success, we tailor strategies to meet your unique needs and guide you every step of the way. Join us today and experience the transformative potential of derivative trading in achieving your financial goals.
                
                </p>
            </div>
        </div>
        <ScrollTop/>
        <Whatsapp/>  
        </>
    )
}
export default Derivatives;