import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./cal.css"
import "./slider.css"
import cal from "../../images/calculator.jpg"
import "../../component/Products/common.css"
import "../../component/Products/sidebar.css"
function LumpSumCalculator() {
  const [principal, setPrincipal] = useState(0);
  const [rateOfReturn, setRateOfReturn] = useState(0);
  const [tenure, setTenure] = useState(0);
  const [futureValue, setFutureValue] = useState(0);

  const calculateFutureValue = () => {
    const futureAmount = principal * Math.pow(1 + rateOfReturn / 100, tenure);
    setFutureValue(futureAmount.toFixed(2));
  };

  return (
    <>
       <div className="header-style" style={{background:"#fff"}}>
       Lump Sum Calculator
        </div>
    <div className="align">
            <div className="inneralign">
            <img className="image" src={cal} alt="" />
                <Link to="#" className='subheading'>Calculator</Link>
                <Link to="/tools/SIP"><p className="sidebar">SIP Calculator</p></Link>
                <Link to="/tools/LUMPSUM"><p className="sidebar">LUMPSUM Calculator</p></Link>
                </div>
                <div style={{justifyContent:"center",width:"100%"}}>
                <div className='wholebody'>
        <div className='wrapper'>
          <div className='bottom'>
      <h3>Lump Sum Investment Calculator</h3>

          </div>
      <div className='bottom'>
        <label className='field'>Initial Principal Amount:</label>
        <div>

        <input type="range"
          min="0"
          max="100000"
          step="10"
          value={principal}
          className="slider"
          onChange={(e) => setPrincipal(parseFloat(e.target.value))}/>
        </div>
          <br/>
           <input type="number" className="top"value={principal} onChange={(e) => setPrincipal(parseFloat(e.target.value))} />
      <br/> <br/>
      </div>
      <div className='bottom'>
        <label className='field'>Annual Rate of Return (%):</label>
        <div>

        <input type="range"
          min="0"
          max="30"
          step="5"
          value={rateOfReturn}
          className="slider"
          onChange={(e) => setRateOfReturn(parseFloat(e.target.value))}/>
        </div>
        <br/>
        <input type="number"  value={rateOfReturn} onChange={(e) => setRateOfReturn(parseFloat(e.target.value))} />
        <br/> <br/>
      </div>
      <div className='bottom'>
        <label className='field'>Investment Tenure (years):</label>
        <div>

        <input type="range"
          min="0"
          max="40"
          step="1"
          value={tenure}
          className="slider"
          onChange={(e) => setTenure(parseFloat(e.target.value))}/>
        </div>
        <br/>
        <input type="number" value={tenure} onChange={(e) => setTenure(parseInt(e.target.value))} />
        <br/> <br/>
      </div>
      <button onClick={calculateFutureValue} className='field'>Calculate</button>
      <div>
        <h3>Future Value (Approx): &#8377;{futureValue}</h3>
      </div>
    </div>
    </div>
    </div>
</div>
    </>
   
  );
}

export default LumpSumCalculator;
