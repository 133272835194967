import { Link } from "react-router-dom";
import insurance from "../../../../images/insurance.jpg"
import "../../../Products/sidebar.css"
import "../../../Products/common.css"
import ScrollTop from "../../../body/Totop/ScrollTop"
import Whatsapp from "../../../body/Totop/Whatsappbutton";
const Life=()=>{
    return(<>
     <div className="header-style" style={{background:"#fff"}}>
            LIFE
        </div>
        <div className="align">
            <div className="inneralign">
            <img className="image" src={insurance} alt=""/>
                <Link to="/products/insurance"><div className="subheading">Insurance</div></Link>
                <Link to="/products/insurance/health"><p className="sidebar">HEALTH</p></Link>
                <Link to="/products/insurance/life"><p className="sidebar">LIFE</p></Link>
                <Link to="/products/insurance/general"><p className="sidebar">GENERAL</p></Link>
                            
            </div>
            <div className="content">
                <div className="title">Life</div>
               <p>Protect your family's financial future with our reliable life insurance policies. Our dedicated team understands the importance of ensuring your loved ones are taken care of, even in your absence. With our customizable plans, you can choose the coverage and benefits that best suit your family's needs.</p>
               <p>From providing financial support for education expenses to covering outstanding debts, our life insurance policies offer peace of mind and security.</p>
               <p>Count on Trend Financial Services to be your trusted partner in safeguarding your family's dreams and aspirations.</p>
            </div>
        </div>
        <ScrollTop />
        <Whatsapp />
       
    </>
    )
}
export default Life;