
import b1 from "../../images/business1.jpg"
import Card from "./Card";
import './mainbody.scss'
import Slider from "./Slider";
import ScrollTop from "./Totop/ScrollTop";
import Whatsapp from "./Totop/Whatsappbutton";


const Mainbody = () => {

    return (
        <>
            {/* <Slider /> */}

            <br></br>
            <Card />

            <section className="about-section">

                <div className="container5" style={{ display: "flex", overflowX: 'hidden' }}>
                    <div className="row">

                        <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2">
                            <div className="inner-column">
                                <div className="sec-title">
                                    <span className="title">About Us</span>
                                    <div className="text">
                                        Trend Financial Services is an AMFI Registered Mutual Fund Distributor & the Exchange Certified Authorized partner (Regn. No.: NSE: AP2258107231, BSE: AP01326101123443) 

                                        We are a Mumbai based distribution house that primarily deals in financial services.

                                        Started in 2021, with an objective to offer varied investment opportunities under one roof. Our clientele transcends local boundaries and is spread nationally. 

                                        Our mission is to carve out highly personalized services to our clientele to help them achieve their financial goals.</div>
                                </div>
                                <div className="btn-box">
                                    {/* <a href="/" className=" btn-style-one" style={{backgroundColor: "#ff7400",color:"#000"}}>Contact Us</a> */}
                                </div>
                            </div>
                        </div>
                        <div className="image-column col-lg-6 ">
                            <div className="inner-column wow ">
                                
                                <figure className="image-1"><a href="/" className="lightbox-image" data-fancybox="images"><img title="Bhushan Kadam" src={b1} alt="" /></a></figure>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollTop />
                <Whatsapp />
            </section>
        </>
    )
}
export default Mainbody;