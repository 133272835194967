import { Link } from "react-router-dom";
import SB from "../../../../images/SB.jpg"
import "../../../Products/sidebar.css"
import "../../../Products/common.css"
import ScrollTop from "../../../body/Totop/ScrollTop"
import Whatsapp from "../../../body/Totop/Whatsappbutton";
const Ipo=()=>{
    
    return(
        <>
        <div className="header-style" style={{background:"#fff"}}>
            IPO
        </div>

        <div className="align">
            <div className="inneralign">
            <img className="image" src={SB}alt=""/>
                <Link to="/products/market"><div className="subheading">Stock Broking</div></Link>
                <Link to="/products/market/equity"><p className="sidebar">EQUITY</p></Link>
                <Link to="/products/market/ipo"><p className="sidebar">IPO</p></Link>
                <Link to="/products/market/derivatives"><p className="sidebar">DERIVATIVES</p></Link>
                <Link to="/products/market/commodities"><p className="sidebar">COMMODITIES</p>   </Link>             
            </div>
            <div className="content">
                <div className="title">What is IPO?</div>
               <p>
                 An IPO (Initial Public Offering) in India follows a similar concept to IPOs in other countries, but it involves the process of a company going public on Indian stock exchanges, such as the Bombay Stock Exchange (BSE) and the National Stock Exchange (NSE). 
                </p>
                <div className="title">   Here are the key aspects of an IPO in India:</div>

<p>
Company Going Public: An IPO marks the first time that a company's shares are made available to the public for purchase on Indian stock exchanges. Prior to the IPO, the company is privately held, with ownership typically concentrated among founders, early investors, and insiders.

</p>
<p>

Regulatory Framework: IPOs in India are regulated by the Securities and Exchange Board of India (SEBI), which is the country's securities market regulator. SEBI sets the rules and guidelines for companies, investment banks, and other participants involved in the IPO process.
</p>
<p>

Lock-In Period: Insiders, including promoters and early investors, are often subject to lock-in agreements that restrict them from selling their shares for a specified period after the IPO to prevent excessive price volatility.

</p>
<p>
Use of Funds: Companies typically use the funds raised through the IPO for various purposes, such as business expansion, debt reduction, acquisitions, working capital, and capital expenditure.

</p>
<p>
Market Debut: On the day of listing, the company's shares become publicly traded, and they are available for purchase and sale by investors on the stock exchange.

</p>
<p>
IPOs in India provide companies with an opportunity to access capital, increase their visibility, and gain access to a broader investor base. For investors, they offer a chance to invest in newly listed companies and potentially benefit from capital appreciation if the stock's price rises. However, IPO investments also come with risks, and it's important for investors to conduct thorough research and consider their investment objectives and risk tolerance before participating in an IPO.

</p>




            </div>
        </div>
        <ScrollTop/>
        <Whatsapp/>  
        </>
    )
}
export default Ipo;