import { Link } from "react-router-dom";
import insurance from "../../../images/insurance.jpg"
import "../../Products/sidebar.css"
import "../../Products/common.css"
import ScrollTop from "../../body/Totop/ScrollTop"
import Whatsapp from "../../body/Totop/Whatsappbutton";
const Insurance=()=>{
    return(<>
    <div className="header-style" style={{background:"#fff"}}>
            INSURANCE
        </div>
        <div className="align">
            <div className="inneralign">
                <img className="image" src={insurance} alt=""/>
                <Link to="/products/insurance"><div className="subheading">Insurance</div></Link>
                <Link to="/products/insurance/health"><p className="sidebar">HEALTH</p></Link>
                <Link to="/products/insurance/life"><p className="sidebar">LIFE</p></Link>
                <Link to="/products/insurance/general"><p className="sidebar">GENERAL</p></Link>
                            
            </div>
            <div className="content">
                <div className="title">What Is Insurance?</div>
               <p>
                Insurance is a contractual arrangement between an individual (or entity) and an insurance company, where the individual, known as the policyholder, pays a regular premium in exchange for financial protection and coverage against specific risks, losses, or events. Insurance provides a safety net to help individuals or businesses manage and mitigate the financial consequences of unexpected or adverse events. 
                </p> 
                
                <div className="title"> Here are some key aspects of insurance:</div>
<p>

Policy: An insurance policy is a legal contract that outlines the terms and conditions of coverage. It specifies the types of risks or events that are covered, the premium amount, the coverage limits, and any exclusions or conditions.
</p>
<p>
Premium: The policyholder pays regular premiums to the insurance company. Premiums can be paid monthly, quarterly, annually, or according to the terms of the policy. The cost of the premium is typically based on the level of coverage, the type of insurance, and the assessed risk.

</p>
<p>
Coverage: Insurance provides coverage for specific risks, which can vary depending on the type of insurance policy. Common types of insurance include health insurance, life insurance, auto insurance, homeowners or renters insurance, and business insurance, among others.

</p>
<p>
Insurer: The insurance company, also known as the insurer, is the entity that underwrites and sells insurance policies. It is responsible for paying out claims to policyholders when covered events occur.

</p>
<p>
Policyholder: The individual or entity that purchases an insurance policy is the policyholder. They are entitled to receive the benefits and coverage specified in the policy when a covered event occurs.

</p>
<p>
Claim: When a covered event occurs, the policyholder can file a claim with the insurance company to receive the financial benefits or compensation as outlined in the policy.
    
</p>
<p>
Coverage Limits: Insurance policies often have coverage limits, which represent the maximum amount the insurer will pay for a covered event. Policyholders may need to be aware of these limits when selecting their coverage.
</p>








            </div>
        </div>
        <ScrollTop />
        <Whatsapp />
    </>
    )
}
export default Insurance;