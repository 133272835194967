import "./contact.css"
const Contactus=()=>{
    return(
        <>
        <div className="header-style" style={{background:"#fff"}}>
           Contact Us
        </div>
        <div className="contact">
    
       
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.940279975094!2d72.83501207586012!3d19.022353053653227!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7cec512b484b1%3A0x32b63f62525dd809!2s1%2C%20Gokhale%20Rd%20N%2C%20Chandrakant%20Dhuru%20Wadi%2C%20Dadar%2C%20Mumbai%2C%20Maharashtra%20400028!5e0!3m2!1sen!2sin!4v1698733947254!5m2!1sen!2sin" width="300" height="300" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

    
<div>Mumbai Office</div>

        </div>
        </>
    )
}
export default Contactus;