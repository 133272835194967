import React, { useState } from 'react';
import "./cal.css"
import "../../component/Products/sidebar.css"
import { Link } from 'react-router-dom';
import cal from "../../images/calculator.jpg"
function SIPCalculator() {
  const [principal, setPrincipal] = useState(0);
  const [monthlyInvestment, setMonthlyInvestment] = useState(0);
  const [rateOfReturn, setRateOfReturn] = useState(0);
  const [tenure, setTenure] = useState(0);
  const [totalValue, setTotalValue] = useState(0);

  const calculateSIP = () => {
    // Convert rate of return to a monthly rate
    const monthlyRate = rateOfReturn / 12 / 100;
    const months = tenure * 12;

    // Calculate SIP value
    const sipValue = Math.ceil((monthlyInvestment * ((Math.pow(1 + monthlyRate, months) - 1) / monthlyRate)) *
      (1 + monthlyRate));

    setTotalValue(principal + sipValue);
  };

  return (<>
   <div className="header-style" style={{background:"#fff"}}>
      SIP Calculator
        </div>
    <div className="align">
      <div className="inneralign">
        <img className="image" src={cal} alt="" />
        <div>

        <Link to="#" className='subheading'>Calculator</Link>
        </div>
        <Link to="/tools/SIP"><p className="sidebar">SIP  Calculator</p></Link>
        <Link to="/tools/LUMPSUM"><p className="sidebar">LUMPSUM Calculator</p></Link>
      </div>
      <div style={{justifyContent:"center",width:"100%"}}>

      <div className='wholebody'>
        <div className='wrapper'>
          <div className='bottom'>


          <h3>SIP Investment Calculator</h3>
          </div>
    <div className='bottom'>
          <label className='field'>Monthly Investment:</label>
          <div>

          <input type="range"
            min="0"
            max="1000000"
            step="10"
            className="slider"
            value={monthlyInvestment}
            onChange={(e) => setMonthlyInvestment(parseFloat(e.target.value))} />
          </div>
          <br/>
          <input type="number" value={monthlyInvestment} onChange={(e) => setMonthlyInvestment(parseFloat(e.target.value))} /><br />
        <br/>
        </div>
        <div className='bottom'>
            <label className='field'>Annual Rate of Return (%):</label>
            <div>

            <input type="range"
            min="0"
            max="30"
            step="10"
            value={rateOfReturn}
            className="slider"
            onChange={(e) => setRateOfReturn(parseFloat(e.target.value))} />
            </div>
        <br/>
            <input type="number" value={rateOfReturn} onChange={(e) => setRateOfReturn(parseFloat(e.target.value))} /><br />
            <br/>
        </div>
       <div className='bottom'>

            <label className='field'>Tenure (years):</label>
            <div>

            <input type="range"
            min="0"
            max="40"
            step="10"
            value={tenure}
            className="slider"
            onChange={(e) => setTenure(parseFloat(e.target.value))} />
            </div>
            <br/>
            <input type="number" value={tenure} onChange={(e) => setTenure(parseFloat(e.target.value))} /><br />
            <br/>
       </div>
       <button onClick={calculateSIP} className='field'>Calculate</button>
       <div>
          <h3>Total Value (Approx): &#8377;{totalValue.toFixed(2)}</h3>
        </div>
        </div>
       
        
        
        
      </div>
      </div>
      </div>


  </>
  );
}

export default SIPCalculator;
