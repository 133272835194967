import "./whats.css"
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
const Whatsapp=()=>{
    return(
    <>
<a href="https://api.whatsapp.com/send?phone=919920369415&text=Hello! I would like to have more information about Trend Finance" class="float" target="_blank">
<i ><WhatsAppIcon/></i>
</a>
    </>
    )
}
export default Whatsapp;