import { Link } from "react-router-dom";
import mutual from "../../../../images/mutual_fund.jpg"
import "../../../Products/sidebar.css"
import "../../../Products/common.css"
import ScrollTop from "../../../body/Totop/ScrollTop"
import Whatsapp from "../../../body/Totop/Whatsappbutton";
const Hybrid = () => {
    return (<>
    <div className="header-style" style={{background:"#fff"}}>
        HYBRID SCHEMES
        </div>
     <div className="align">
            <div className="inneralign">
            <img className="image" src={mutual}alt=""/>
            <Link to="/products/mutual_fund"><div className="subheading">Mutual Fund</div></Link>
                <Link to="/products/mutual_fund/equityS"><p className="sidebar">EQUITY SCHEMES</p></Link>
                <Link to="/products/mutual_fund/debt"><p className="sidebar">DEBT SCHEMES</p></Link>
                <Link to="/products/mutual_fund/hybrid"><p className="sidebar">HYBRID SCHEMES</p></Link>
                <Link to="/products/mutual_fund/solution"><p className="sidebar">SOLUTION ORIENTED SCHEMES</p></Link>
             
                
            </div>
            <div className="content">
                <div className="title">What Is Hybrid Schemes?</div>
               <p>
               Hybrid schemes, also known as balanced funds, are mutual funds that invest in a mix of both equity and debt instruments. They offer a balanced portfolio, providing potential for capital growth from equities and stability from debt securities. </p>
               <p>Hybrid schemes are suitable for investors seeking moderate risk and returns with a diversification benefit.
               </p>
            </div>
        </div>
        <ScrollTop />
        <Whatsapp />
        
    </>)
}
export default Hybrid;