import { BrowserRouter, Route, Routes } from "react-router-dom";

import Mainbody from "./component/body/Mainbody";
import Footer from "./component/footer/Footer";
import Navbar from "./component/nav/Navbar";
import Mutaul from "./component/Products/Funds/Mutuals";
import { useEffect, useState } from "react";
import Testimonials from "./component/aboutus/Testimonials";
import Market from "./component/Products/Markets/Market";
import Insurance from "./component/Products/Insurance/Insurance";
import Others from "./component/Products/Other/Other";
import Equity from "./component/Products/Markets/Equity/Equity";
import Ipo from "./component/Products/Markets/IPO/Ipo";
import Commodities from "./component/Products/Markets/COMMODITIES/Commodities";
import Derivatives from "./component/Products/Markets/DERIVATIVES/Derivatives";
import Debts from "./component/Products/Funds/Debt/Debts";
import Hybrid from "./component/Products/Funds/Hybrid/Hybrid";
import Solution from "./component/Products/Funds/Solution/Solution";
import OtherS from "./component/Products/Funds/OtherS/OtherS";
import EquityS from "./component/Products/Funds/EquityS/EquityS";
import Health from "./component/Products/Insurance/Health/Health";
import Life from "./component/Products/Insurance/Life/Life";
import General from "./component/Products/Insurance/General/General";
import PMS from "./component/Products/Other/PMS/PMS";
import Loans from "./component/Products/Other/LOANS/Loans";
import Bonds from "./component/Products/Other/BONDS/Bonds";
import Contactus from "./component/aboutus/Contactus";
import Alternate from "./component/Products/Other/Alternate/Alternate";
import SIPCalculator from "./component/calculator/calculator";
import LumpSumCalculator from "./component/calculator/lumpsum";
import About from "./component/aboutus/about";
// import About from "./component/aboutus/about";


export default function App() {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
      window.addEventListener('scroll', () => {
          if (window.scrollY > 100) {
              setIsVisible(true);
              
          } else {
              setIsVisible(false);
          }
      });
  }, []);

  const goTop = () => {
      window.scrollTo({
          top: 0,
          behavior: 'smooth',
      });
  };
  return (
    <BrowserRouter>
      <Navbar/>
      <Routes>
        <Route path="/" element={<Mainbody/>}/>     
       
        <Route path="/contactus" element={<Contactus/>}/>
        <Route path="/aboutus/about" element={<About/>}/>
        <Route path="/tools/SIP" element={<SIPCalculator/>}/>
        <Route path="/tools/LUMPSUM" element={<LumpSumCalculator/>}/>
        
        <Route path="/products/market" element={<Market/>}/>
        <Route path="/products/market/equity" element={<Equity/>}/>
        <Route path="/products/market/ipo" element={<Ipo/>}/>
        <Route path="/products/market/derivatives" element={<Derivatives/>}/>
        <Route path="/products/market/commodities" element={<Commodities/>}/>

        <Route path="/products/mutual_fund" element={<Mutaul/>}/>
        <Route path="/products/mutual_fund/equityS" element={<EquityS/>}/>
        <Route path="/products/mutual_fund/debt" element={<Debts/>}/>
        <Route path="/products/mutual_fund/hybrid" element={<Hybrid/>}/>
        <Route path="/products/mutual_fund/solution" element={<Solution/>}/>
        <Route path="/products/mutual_fund/otherS" element={<OtherS/>}/>

        <Route path="/products/insurance" element={<Insurance/>}/>
        <Route path="/products/insurance/health" element={<Health/>}/>
        <Route path="/products/insurance/life" element={<Life/>}/>
        <Route path="/products/insurance/general" element={<General/>}/>

        <Route path="/products/others" element={<Others/>}/>  
        <Route path="/products/others/pms" element={<PMS/>}/>  
        <Route path="/products/others/loans" element={<Loans/>}/>  
        <Route path="/products/others/bonds" element={<Bonds/>}/>  
        <Route path="/products/others/alternate" element={<Alternate/>}/>  

        <Route path="/aboutus/testimonials" element={<Testimonials/>}/>
       
      </Routes>
      <Footer/>
      
    </BrowserRouter>
   
  );
}
