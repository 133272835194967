import { Link } from "react-router-dom";
import SB from "../../../../images/SB.jpg"
import "../../../Products/sidebar.css"
import "../../../Products/common.css"
import ScrollTop from "../../../body/Totop/ScrollTop"
import Whatsapp from "../../../body/Totop/Whatsappbutton";
const Equity=()=>{
    return(
        <>
         <div className="header-style" style={{background:"#fff"}}>
          EQUITY
        </div>
        <div className="align">
            <div className="inneralign">
                <img className="image" src={SB}alt=""/>
                <Link to="/products/market"><div className="subheading">Stock Broking</div></Link>
                <Link to="/products/market/equity"><p className="sidebar">EQUITY</p></Link>
                <Link to="/products/market/ipo"><p className="sidebar">IPO</p></Link>
                <Link to="/products/market/derivatives"><p className="sidebar">DERIVATIVES</p></Link>
                <Link to="/products/market/commodities"><p className="sidebar">COMMODITIES</p>   </Link>                  
            </div>
            <div className="content">
                <div className="title">EQUITY</div>
                <p>
                    Experience the thrill and potential of equity trading with our financial services firm. As experts in the field, we offer a comprehensive range of equity trading services designed to maximize your returns. With cutting-edge technology, in-depth market analysis, and a dedicated team of professionals, we provide you with the tools and support you need to make informed investment decisions. 
                    </p>
                    <p>
                        Join us for seamless execution, personalized guidance, and a track record of delivering exceptional results. Unlock the power of equity trading and take your investments to new heights with us.
                        
                        </p>
            </div>
        </div>
        <ScrollTop/>
        <Whatsapp/>  
        </>
    )
}
export default Equity;