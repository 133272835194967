import { Link } from "react-router-dom";
import mutual from "../../../../images/mutual_fund.jpg"
import "../../../Products/sidebar.css"
import "../../../Products/common.css"
import ScrollTop from "../../../body/Totop/ScrollTop"
import Whatsapp from "../../../body/Totop/Whatsappbutton";
const OtherS = () => {
    return (<>
    <div className="header-style" style={{background:"#fff"}}>
        OTHERS ORIENTED SCHEMES
        </div>
     <div className="align">
            <div className="inneralign">
            <img className="image" src={mutual}alt=""/>
            <Link to="/products/mutual_fund"><div className="subheading">Mutual Fund</div></Link>
                <Link to="/products/mutual_fund/equityS"><p className="sidebar">EQUITY SCHEMES</p></Link>
                <Link to="/products/mutual_fund/debt"><p className="sidebar">DEBT SCHEMES</p></Link>
                <Link to="/products/mutual_fund/hybrid"><p className="sidebar">HYBRID SCHEMES</p></Link>
                <Link to="/products/mutual_fund/solution"><p className="sidebar">SOLUTION ORIENTED SCHEMES</p></Link>
             
                
            </div>
            <div className="content">
                <div className="title">What Is Mutual Fund?</div>
               <p>A mutual fund is simply a financial intermediary that allows a group of investors to pool their money together with a predetermined investment objective. The mutual fund will have a fund manager who is responsible for investing the pooled money into specific securities (usually stocks or bonds). Mutual funds are one of the best investments ever created because they are very cost efficient and very easy to invest in (you don’t have to figure out which stocks or bonds to buy).</p> 

               <div className="title">How It Works?</div>
                <p>A mutual fund is a collection of stocks, bonds, or other securities owned by a group of investors and managed by a professional investment company. For an individual investor, having a diversified portfolio is difficult. Mutual funds helps the individual investors to invest in equity and debt securities simultaneously. When investors invest a particular amount in mutual funds, he becomes the unit holder of corresponding units. In turn, mutual funds invest unit holders’ money in stocks, bonds or other securities that earn interest or dividend. This money is distributed to the unit holders. If the fund gets money by selling some stocks at higher price the unit holders are liable to get the capital gains.</p>

                <div className="title">Advantages Of Mutual Fund:</div>
                <p>&#x2022; Professional Management: The primary advantage of funds is the professional management of your money. Investors purchase funds because they do not have the time or the expertise to manage their own portfolio. A mutual fund is a relatively inexpensive way for a small investor to get a full-time manager to make and monitor the investments.</p>

                <p>&#x2022; Diversification: By owning “shares”(known as “units”) in a mutual fund instead of owning individual stocks or bonds, your risk is spread out. The idea behind diversification is to invest in a number of assets so that a loss in any particular investment is minimized by gains in others. In other words, the more stocks and bonds you own, the less any one of them can hurt you. Large mutual funds typically own hundreds of different stocks in many different industries. It wouldn’t be possible for a small investor to build this kind of portfolio with a small amount of money.</p>

                <p>&#x2022; Economies of Scale: Because a mutual fund buys and sells large amounts of securities at a time, its transaction costs are lower than you as an individual would pay.</p>

                <p>&#x2022; Liquidity: Just like an individual stock, a mutual fund allows you to sell the units at any time.</p>

                <p>&#x2022; Simplicity: Buying a mutual fund is easy! The minimum investment is also very small. As little as Rs 500 can be invested on a monthly basis. Just contact us to know more.</p>
            </div>
        </div>
        <ScrollTop />
        <Whatsapp />
        
    </>)
}
export default OtherS;