import { Link } from "react-router-dom";
import mutual from "../../../../images/mutual_fund.jpg"
import "../../../Products/sidebar.css"
import "../../../Products/common.css"
import ScrollTop from "../../../body/Totop/ScrollTop"
import Whatsapp from "../../../body/Totop/Whatsappbutton";
const Debts = () => {
    return (<>
        <div className="header-style" style={{ background: "#fff" }}>
            DEBT SCHEMES
        </div>
        <div className="align">
            <div className="inneralign">
                <img className="image" src={mutual} alt="" />
                <Link to="/products/mutual_fund"><div className="subheading">Mutual Fund</div></Link>
                <Link to="/products/mutual_fund/equityS"><p className="sidebar">EQUITY SCHEMES</p></Link>
                <Link to="/products/mutual_fund/debt"><p className="sidebar">DEBT SCHEMES</p></Link>
                <Link to="/products/mutual_fund/hybrid"><p className="sidebar">HYBRID SCHEMES</p></Link>
                <Link to="/products/mutual_fund/solution"><p className="sidebar">SOLUTION ORIENTED SCHEMES</p></Link>
            </div>          
            <div className="content">
            <div className="title">What Is Debt schemes?</div>
                <p>        
                    Debt schemes are mutual funds that primarily invest in fixed-income securities like government bonds, corporate bonds, and money market instruments. These schemes aim to provide stable returns and are considered less risky compared to equity schemes. Debt funds are suitable for investors looking for regular income and capital preservation.
                </p>
            </div>
        </div>
        <ScrollTop />
        <Whatsapp />

    </>)
}
export default Debts;