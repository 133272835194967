import { Link } from "react-router-dom";
import gunny  from "../../../../images/gunny.jpg"
import "../../../Products/sidebar.css"
import "../../../Products/common.css"
import ScrollTop from "../../../body/Totop/ScrollTop"
import Whatsapp from "../../../body/Totop/Whatsappbutton";
const PMS=()=>{
    
    return(
        <>
        <div className="header-style" style={{background:"#fff"}}>
        PORTFOLIO MANAGEMENT SERVICE(PMS)
        </div>

        <div className="align">
            <div className="inneralign">
            <img className="image" src={gunny}alt=""/>
                <Link to="/products/others/pms"><div className="subheading">Other</div></Link>
                <Link to="/products/others/pms"><p className="sidebar">PORTFOLIO MANAGEMENT SERVICE(PMS)</p></Link>
                {/* <Link to="/products/others/alternate"><p className="sidebar">ALTERNATE FUNDS</p></Link> */}
                <Link to="/products/others/bonds"><p className="sidebar">BONDS & NCD</p></Link>
                <Link to="/products/others/loans"><p className="sidebar">LOANS</p></Link>  
                    
            </div>
            <div className="content">
                <div className="title">What Is Portfolio Management Service?</div>
              <p>
                 PMS is a specialized investment service offered by portfolio managers or professional fund managers to individual investors, high-net-worth individuals (HNIs), and institutions. The primary objective of PMS is to create and manage customized investment portfolios tailored to the specific financial goals and risk profiles of clients. 
                
                </p> 
                <div className="title">Here are some key features and aspects of Portfolio Management Services (PMS) in India:</div>
<p>&#x2022;Customized Portfolio: PMS providers create and manage individualized investment portfolios for clients. These portfolios are designed to meet the client's unique investment objectives, risk tolerance, and time horizon.
</p>
<p>&#x2022;Transparency: PMS providers offer regular reporting and transparency regarding the portfolio's performance, holdings, and transactions. Clients can monitor their investments and receive periodic updates.
</p>
<p>&#x2022;Risk Profile Assessment: PMS providers assess the risk profile and investment objectives of clients before creating a portfolio. This helps in constructing a portfolio that aligns with the client's financial goals.
</p>
<p>&#x2022;Asset Allocation: PMS providers use asset allocation strategies to distribute investments among different asset classes, such as equities, fixed income, and cash, based on the client's risk tolerance and objectives.
</p>
<p>&#x2022;Taxation: The taxation of PMS portfolios in India is subject to applicable tax laws. Capital gains on equity holdings may be subject to different tax rates than gains on other asset classes.
</p>
<p>&#x2022;Portfolio Management Services provide an opportunity for investors to benefit from professional management and customization of their investment portfolios. They are particularly suited for investors with significant capital and those who prefer a personalized approach to investing. It's important for investors to carefully consider the track record, fees, and investment strategy of PMS providers and ensure that their chosen provider aligns with their financial goals and risk tolerance.
</p>






            </div>
        </div>
        <ScrollTop/>
        <Whatsapp/>  
        </>
    )
}
export default PMS;