import { Link } from "react-router-dom";
import gunny  from "../../../../images/gunny.jpg"
import "../../../Products/sidebar.css"
import "../../../Products/common.css"
import ScrollTop from "../../../body/Totop/ScrollTop"
import Whatsapp from "../../../body/Totop/Whatsappbutton";
const Loans=()=>{
    
    return(
        <>
        <div className="header-style" style={{background:"#fff"}}>
            LOANS
        </div>

        <div className="align">
            <div className="inneralign">
            <img className="image" src={gunny}alt=""/>
                <Link to="/products/others/pms"><div className="subheading">Other</div></Link>
                <Link to="/products/others/pms"><p className="sidebar">PORTFOLIO MANAGEMENT SERVICE(PMS)</p></Link>
                {/* <Link to="/products/others/alternate"><p className="sidebar">ALTERNATE FUNDS</p></Link> */}
                <Link to="/products/others/bonds"><p className="sidebar">BONDS & NCD</p></Link>
                <Link to="/products/others/loans"><p className="sidebar">LOANS</p></Link>           
            </div>
            <div className="content">
                <div className="title">What Is Loan?</div>
                <p>Loans are financial arrangements in which one party (the lender) provides money or assets to another party (the borrower) with the expectation that the borrower will repay the amount borrowed, usually with interest, over a specified period. Loans can serve various purposes, from personal financing to business expansion.</p>
                <div className="title">  Here are some common types of loans:</div>
            <p>
            Home Loan, also known as a mortgage, is a type of loan specifically designed to help individuals or families purchase a home. Home loans are typically long-term loans with a repayment period that can extend for many years, often 15, 20, or 30 years.
            </p>
            <p>
            &#x2022; Fixed-Rate Mortgage: With a fixed-rate mortgage, the interest rate remains constant throughout the life of the loan. This provides predictability in monthly payments.
</p>
<p> &#x2022;Adjustable-Rate Mortgage (ARM): An ARM has an initial fixed-rate period, typically 5, 7, or 10 years, after which the interest rate can adjust periodically based on market conditions.
</p>
<p> &#x2022;FHA Loans: These are insured by the Federal Housing Administration and often require lower down payments and have more flexible qualification requirements.
            </p>
            </div>
        </div>
        <ScrollTop/>
        <Whatsapp/>  
        </>
    )
}
export default Loans;